html,
body {
  background-color: #231e56;
  margin: 0;
  padding: 0;
  color: #d3d3d3;
}

.wrapper {
  display: flex;
  flex-direction: column;
}

.content {
  flex: 1;
  min-height: calc(100vh - 50px);
}

.footer {
  height: 50px;
  background-color: #e2e2e2;
  position: relative;
  transition: transform 0.3s ease;
}

.footer:hover {
  animation: calm 2s infinite;
}

/* Navbar Styling */
.nav-bar {
  width: 100%;
  height: 100px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.25);
}

.navigation {
  display: flex;
  margin-top: 10px;
  margin-right: 10px;
}

.contact {
  text-align: center;
  font-weight: 500;
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 24px;
  margin-left: 4%;
  margin-right: 4%;
  margin-bottom: 1%;
}


/* Title Styling */
header,
.title,
.subheader,
.subheaderContentAbout,
.subheaderContent,
.subheaderContentResume,
.subheaderFootnote,
.subheaderSection {
  text-align: center;
  font-weight: 500;
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
}

.subheaderContentAbout {
  padding: 50px;
  font-size: 50px;
}

.subheaderWrapper {
  padding: 10px;
  margin: 8%;
  display: flex;
  justify-content: center;
  align-items: center;
}


header {
  padding-left: 10px;
  padding-right: 10px;
  margin-top: 10px;
  margin-left: 10px;
  margin-right: 10px;
  font-size: 50px;
  max-width: 100%;
}

.theheader {
  margin-top: 10px;
  padding-bottom: 50px;
}

@keyframes calm {
  0% {
    background-color: #f5f5dc;
  }
  20% {
    background-color: #f0f8ff;
  }
  40% {
    background-color: #add8e6;
  }
  60% {
    background-color: #b0e0e6;
  }
  80% {
    background-color: #f5deb3;
  }
  100% {
    background-color: #ffe4c4;
  }
}

.subheader {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 30px;
  margin-bottom: 10px;
}

.subheaderContent,
.subheaderFootnote,
.subheaderSection {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 18px;
  margin-left: 4%;
  margin-right: 4%;
}

.subheaderFootnote {
  font-size: 14px;
  margin-top: 0;
}

.subheaderSection {
  font-family: "Courier New", "Lucida Console", monospace;
  font-weight: 550;
  font-size: 20px;
}

/* Button Styling */
.roundB,
.contactB {
  font-family: "Roboto", Arial, sans-serif;
  font-weight: 300;
  background-color: #000000;
  border-radius: 8px;
  border: none;
  color: #ecf3ea;
  padding: 10px;
  text-align: center;
  text-decoration: none;
  font-size: 16px;
  margin: 4px 2px;
}

.roundB:hover,
.contactB:hover {
  background-color: #434343;
}

.contactB {
  font-size: 20px;
  padding: 18px 14px;
}

.active {
  background-color: #6a6a6a;
}

.active:hover,
.active:active {
  background-color: #6a6a6a;
}

/* Container Styling */
.container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.title {
  margin-left: 20%;
  font-size: 35px;
  text-align: center;
}

.containerResume {
  margin-bottom: 20px;
  display: flex;
  flex-direction: column;
  justify-content: left;
  align-items: center;
}

.containerResumeContent {
  display: flex;
  flex-direction: column;
  justify-content: left;
  align-items: center;
  margin-left: 20px;
}

.subheaderContentResume {
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  text-align: left;
  align-items: flex-start;
  width: 80%;
  padding: 10px;
  box-sizing: border-box;
}

.containerImage {
  display: flex;
  justify-content: center;
  align-items: center;
}

.projectImage {
  max-width: 25%;
  max-height: 30vh;
  margin-left: 50px;
  height: auto;
  margin-bottom: 20px;
}

/* Divider Styling */
.nav-divider {
  margin-bottom: 20px;
  width: 100%;
}

/* Burrito Styling */
.mapContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}

a {
  text-decoration: none;
}

.row {
  display: table;
  width: 100%;
  table-layout: fixed;
  border-spacing: 10px;
}

.column {
  display: table-cell;
}

/* Animation Styling */
.waviy {
  text-align: center;
  margin-top: 1%;
  position: relative;
  font-size: 30px;
}

.waviy span {
  position: relative;
  display: inline-block;
  margin: 1%;
}


/* PDF Display Styling */
.pdfDisplay {
  display: flex;
  justify-content: center;
  width: 100%;
  height: 90vh;
}

ul,
p {
  font-size: 18px;
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
}

.weatherwidget-io {
  margin-top: 30%;
  margin-left: auto;
  margin-right: auto;
  position: relative;
  max-width: 15%;
  max-height: auto;
  display: flex;
  align-items: center;
  font-size: 18px;
}

.listBurritos {
  margin-bottom: 5px;
}

.listBurritos:before {
  color: #cbcbcb;
  content: '\1F32F';
  margin-left: -20px;
  margin-right: 10px;
} 

.burritoList {
  font-size: 20px;
  color: #cbcbcb;
}

.burritoList:hover {
  color: #9e9e9e;
}

.subheaderContentBurrito {
  text-align: center;
  font-weight: 500;
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  text-align: left;
  align-items: flex-start;
  padding: 10px;
  box-sizing: border-box;
  font-size: 12px;
}




/* MOBILE FRIENDLY!!! */
@media screen and (max-width: 800px) {
  html,
  body {
    font-size: 14px;
  }

  header {
    width: 100%;
    font-size: 26px;
  }
  .title {
    display: none;
  }

  .mobilestackcontainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .subheader,
  .subheaderContent,
  .subheaderFootnote,
  .subheaderSection,
  .roundB {
    font-size: 16px;
  }

.subheaderContentAbout {
  font-size: 30px;
}

  ul,
  .subheaderContentResume {
    font-size: 14px;
  }

  .roundB {
    padding: 8px;
    margin: 1px;
  }
  
  .navigation {
    justify-content: center;
    flex-direction: row;
    width: 100%;
  }

  .projectImage {
    display: none;
  }

  .subheaderContent,
  .subheaderFootnote,
  .subheaderContentAbout,
  .subheaderSection {
    margin-left: 5px;
    margin-right: 5px;
  }
  .subheaderContentAbout {
    padding: 10px;
  }
}