*{
    margin: 0;
    padding: 0;
  }
  section{
    position: bottom;
    width: 100%;
    height: 0vh;
    overflow: hidden;
  }
  section .air{
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100px;
    background: url(https://1.bp.blogspot.com/-xQUc-TovqDk/XdxogmMqIRI/AAAAAAAACvI/AizpnE509UMGBcTiLJ58BC6iViPYGYQfQCLcBGAsYHQ/s1600/wave.png);
    background-size: 1000px 100px
  }
  section .air.air1{
    animation: wave 90s linear infinite;
    z-index: 1000;
    opacity: 0.09;
    animation-delay: 0s;
    bottom: 0;
  }
  section .air.air2{
    animation: wave2 50s linear infinite;
    z-index: 999;
    opacity: 0.06;
    animation-delay: -5s;
    bottom: 0;
  }
  section .air.air3{
    animation: wave 130s linear infinite;
    z-index: 998;
    opacity: 0.03;
    animation-delay: -2s;
    bottom: 0;
  }
  @keyframes wave{
    0%{
      background-position-x: 0px; 
    }
    100%{
      background-position-x: 1000px; 
    }
  }
  @keyframes wave2{
    0%{
      background-position-x: 0px; 
    }
    100%{
      background-position-x: -1000px; 
    }
  }

  @media screen and (max-height: 550px) {
    section .air {
        display: none;
    }
}