@keyframes slideOutLeft {
    from {
      transform: translateX(0);
      opacity: 1;
    }
    to {
      transform: translateX(-100%);
      opacity: 0;
    }
  }
  
  @keyframes slideInRight {
    from {
      transform: translateX(100%);
      opacity: 0;
    }
    to {
      transform: translateX(0);
      opacity: 1;
    }
  }
  
  .slide-out {
    margin-top: 10px;
    animation: slideOutLeft 0.5s forwards;
  }
  
  .slide-in {
    margin-top: 10px;
    animation: slideInRight 0.5s forwards;
  }
  