/* TIMELINE STUFF vv */

/* The actual timeline (the vertical ruler) */
.timeline {
  position: relative;
  max-width: 1200px;
  margin: 0 auto;
}

/* The actual timeline (the vertical ruler) */
.timeline::after {
  content: '';
  position: absolute;
  width: 6px;
  background-color: #9dbcfa;
  top: 0;
  bottom: 0;
  left: 31px;
}

/* Container around content */
.timelineContainer {
  padding: 10px 40px;
  position: relative;
  background-color: inherit;
  width: 100%;
  padding-left: 70px;
  padding-right: 25px;
}

/* The circles on the timeline */
.timelineContainer::after {
  content: '';
  position: absolute;
  width: 25px;
  height: 25px;
  left: 15px;
  background-color: rgb(198, 198, 198);
  border: 4px solid #636363;
  top: 15px;
  border-radius: 50%;
  z-index: 1;
}

/* Arrows (leftwards for both sides) */
.timelineContainer::before {
  content: " ";
  height: 0;
  position: absolute;
  top: 22px;
  width: 0;
  z-index: 1;
  left: 60px;
  border: medium solid rgb(198, 198, 198);
  border-width: 10px 10px 10px 0;
  border-color: transparent rgb(198, 198, 198) transparent transparent;
}

/* The actual content */
.timelineContent {
  padding: 20px 30px;
  background-color: rgb(24, 24, 32);
  border-radius: 6px;
}

h2 {
  font-size: 20px;
  margin-bottom: -15px;
}

h5 {
  margin-left: 5%;
  font-weight: 500;
}

p {
  font-size: 18px;
}

/* Removing left and right classes as they are no longer needed */
.right, .left {
  left: 0;
}

/* Media queries - Font size adjustments for screens less than 800px wide */
@media screen and (max-width: 800px) {
  h2 {
    font-size: 14px;
  }
  
  h5 {
    font-size: 13px;
  }

  p {
    font-size: 13px;
  }
}